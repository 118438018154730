import { Ref, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type{ Aggregation, CategoryTree, Products } from '~/modules/GraphQL/types';
import categoryMetaGql from '~/modules/catalog/category/composables/useCategory/categoryMeta.gql';
import type {
  UseCategoryErrors,
  UseCategoryInterface,
  UseCategoryParamsInput,
  UseCategoryMetaParamsInput,
} from './useCategory';
import {FacetSearchParams, useApi } from "~/composables";
import getCategoryList from "~/modules/catalog/category/composables/useFacet/getCategoryList.gql";
import { GetProductSearchParams } from '~/modules/catalog/product/types';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import GetProductFilterByCategoryQuery from '~/modules/catalog/category/components/filters/command/getProductFilterByCategory.gql';
/**
 * @public
 *
 * Allows loading categories from Magento API. It
 * is commonly used in navigation menus, and provides the load function and
 * refs for the categories, loading and error.
 *
 * See the {@link UseCategoryInterface} for a list of methods and values available in this composable.
 *
 * @remarks
 *
 * Under the hood, it calls the following Server Middleware API method:
 *
 * - {@link @vue-storefront/magento-api#categoryList} for loading category list;
 *
 * It is currently used in:
 *
 * - `components/AppHeader.vue`
 *
 * - `components/MobileMenuSidebar.vue`
 *
 * @example
 *
 * Load categories on client side using the `onMounted` Composition API hook:
 *
 * ```vue
 * <template>
 *   <div v-if="loading">
 *     Loading categories…
 *   </div>
 *   <div v-else-if="error.load">
 *     Error: {{ error.load.message }}
 *   </div>
 *   <div v-else>
 *     <!-- Display 'categories' -->
 *   </div>
 * </template>
 *
 * <script>
 * import { onMounted } from '@nuxtjs/composition-api';
 * import { useCategory } from '~/modules/catalog/category/composables/useCategory';
 *
 * export default {
 *   setup() {
 *     const { categories, error, load, loading } = useCategory();
 *
 *     onMounted(async () => {
 *       await load({ pageSize: 10 });
 *     });
 *
 *     return {
 *       error,
 *       loading,
 *       categories,
 *     };
 *   },
 * };
 * </script>
 * ```
 */
export function useCategory(): UseCategoryInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseCategoryErrors> = ref({
    load: null,
    loadCategoryMeta: null,
  });
  const categories: Ref<Array<CategoryTree>> = ref(null);
  const { query } = useApi();
  const load = async (params: UseCategoryParamsInput) => {
    
    try {
      loading.value = true;
      const data = await query<any>(getCategoryList, params);
      categories.value = data?.data?.categories?.items ?? [];
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCategory/load', err);
    } finally {
      loading.value = false;
    }
  };

  const loadCategoryMeta = async (params: UseCategoryMetaParamsInput): Promise<CategoryTree | null> => {
    let categoryMeta = null;

    try {
      loading.value = true;

      const { data } = await app.context.$vsf.$magento.api.customQuery({
        query: categoryMetaGql,
        queryVariables: {
          filters: {
            category_uid: {
              eq: params.category_uid,
            },
          },
        },
        customHeaders: params?.customHeaders,
      });
      categoryMeta = data.categories.items[0] || null;
      error.value.loadCategoryMeta = null;
    } catch (err) {
      error.value.loadCategoryMeta = err;
      Logger.error('useCategory/loadCategoryMeta', err);
    } finally {
      loading.value = false;
    }

    return categoryMeta;
  };

  const loadFilters = async (params?: FacetSearchParams): Promise<Aggregation[]>  => {
    const productSearchParams: GetProductSearchParams = {
      search: params.term ? params.term : '',
      filter: params.filters ? createProductAttributeFilterInput(params) : {},
    };
    const { data } = await query<{ products: Products }>(GetProductFilterByCategoryQuery, productSearchParams);

    return data?.products?.aggregations ?? [];
  }

  return {
    load,
    loadCategoryMeta,
    loadFilters,
    loading,
    error,
    categories,
  };
}

export function createCustomMenu() {
  const menu = json =>
    json.reduce((acc, item) => {
      const cat = {
        include_in_menu: 1,
        is_anchor: 1,
        level: 2,
        name: "",
        position: 1,
        product_count: 0,
        uid: "",
        url_path: "",
        url_suffix: "",
        children: [],
        __typename: "CategoryTree",
        redirect_code: 1,
      };
      if (item.child?.length > 0) {
        cat.children = menu(item.child);
      }
      if (item.path)
        cat.url_path = item.path;
      if (item.uid)
        cat.uid = item.uid
      cat.name = item.name;
      acc.push(cat);
      return acc;
    }, []);
  return menu;
}

export * from './useCategory';

export default useCategory;
